@import '~antd/es/style/themes/default.less';
@import '~antd/dist/antd.css';
@import '~antd/dist/antd.less';

@import '~swiper/swiper-bundle.css';

@font-face {
  font-family: 'DFSoGei-W5';
  src: url('./resources/assets/fonts/DFSoGei-W5-WIN-RKSJ-H.woff2') format('woff2'),
      url('./resources/assets/fonts/DFSoGei-W5-WIN-RKSJ-H.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body {
  font-family: 'DFSoGei-W5', sans-serif;
}

p {
  margin-bottom: 0;
}

